import React, { useEffect, useState } from "react";
import { compose, withActions, withAppContext } from "containers/HOC";
import { Panel, PanelBody } from "components/Panel";
import { Card } from "components/CreditCards";
import FarmList from "./FarmList";
import BulkPayFarmList from "containers/Dashboard/FarmList";
import ActionLink from "components/ActionLink";
import Button from "components/Button/Button";
import { getNonRenewals, getRenewals, deleteCard, deleteAutoRenew, setCardForFarms, getMyOrgBalance } from "./_actions";
import { useModal } from "common/effects";
import CreditCardModal from "./CreditCardModal";
import Loader from "components/Loader";
import moment from "moment";
import { useSelector } from "react-redux";
import BalanceSummary from "components/BalanceSummary";
import { useConfirm } from "common/hooks";

const MyOrgRenewals = ({ getRenewals, deleteCard, deleteAutoRenew, setCardForFarms, getNonRenewals, authContext, getMyOrgBalance }) => {
    const [renewals, setRenewals] = useState([]);
    const [farms, setFarms] = useState([]);
    const [selectedCreditCardFarmIds, setSelectedCreditCardFarmIds] = React.useState([]);
    const [selectedInvoiceFarmIds, setSelectedInvoiceFarmIds] = React.useState([]);
    const [selectedNoRenewalFarmIds, setSelectedNoRenewalFarmIds] = React.useState([]);
    const [changeList, setChangeList] = React.useState([]);
    const { openModal, getModal } = useModal();
    const [loading, setLoading] = React.useState(true);
    const balance = useSelector((state) => state.myOrg.balance);
    const confirm = useConfirm();

    const fetchFarmsAndRenewals = () => {
        setLoading(true);
        getRenewals()
            .then((r) => {
                setRenewals(r);
                setLoading(false);
            })
            .catch((error) => console.log(error));
        getNonRenewals(authContext.accountId)
            .then((r) => {
                setFarms(r);
            })
            .catch((error) => console.log(error));
    };

    useEffect(() => {
        getMyOrgBalance(authContext.accountId);
    }, [authContext.accountId, getMyOrgBalance]);

    useEffect(() => {
        getRenewals()
            .then((r) => {
                setRenewals(r);
                setLoading(false);
            })
            .catch((error) => console.log(error));
    }, [getRenewals]);

    useEffect(() => {
        getNonRenewals(authContext.accountId)
            .then((r) => {
                setFarms(r);
            })
            .catch((error) => console.log(error));
    }, [authContext.accountId, getNonRenewals]);

    const actionDeleteCard = async (id) => {
        await deleteCard(id);
        fetchFarmsAndRenewals();
    };

    const deleteCardConfirm = (card) => {
        const extMessage = renewals && renewals.length > 1 && card.farms && card.farms.length > 0 ? `Farms associated with this payment method will be moved to your other payment method.` : renewals && renewals.length === 1 && card.farms && card.farms.length > 0 ? "Farms associated with this payment method will no longer auto renew." : "";
        confirm(`Are you sure you want to delete this payment method? ${extMessage}`, () => actionDeleteCard(card.paymentToken.id));
    };

    const changeCard = (list) => {
        setChangeList(list);
        const modalProps = {
            cards: renewals.filter((r) => !r.paymentToken.isExpired).map((r) => r.paymentToken),
        };
        openModal("CreditCardModal", modalProps);
    };

    const setNewCard = async (cardId) => {
        await setCardForFarms(changeList, cardId);
        fetchFarmsAndRenewals();
        setChangeList([]);
        setSelectedCreditCardFarmIds([]);
        setSelectedInvoiceFarmIds([]);
        setSelectedNoRenewalFarmIds([]);
    };

    const removeFarms = async (list) => {
        await deleteAutoRenew(list);
        fetchFarmsAndRenewals();
        setChangeList([]);
        setSelectedCreditCardFarmIds([]);
        setSelectedInvoiceFarmIds([]);
        setSelectedNoRenewalFarmIds([]);
    };

    const removeFarmsConfirm = (list) => {
        confirm(`Are you sure you want to delete auto renew for the ${list.length > 1 ? list.length + " selected farms?" : "selected farm?"}`, () => removeFarms(list));
    };

    const creditCardModal = getModal("CreditCardModal");

    if (loading) return <Loader message="Loading..." />;

    if (!renewals || renewals.length === 0) {
        return (
            <div className="Tile-body-message">
                <span className="u-text-lg u-textBold">You do not have any saved payment methods</span>
                <p></p>
            </div>
        );
    }

    const farmCount = renewals.reduce((t, r) => (t += r.farms.length), 0);
    const cardRewewals = renewals.filter((r) => r.paymentToken?.paymentMethod === "CreditCard");
    const invoiceRewewals = renewals.filter((r) => r.paymentToken?.paymentMethod === "Invoice");
    const expiredFarms = farms.filter((f) => f.expired);
    const farmsWithOtherOrgs = farms.filter((f) => f.paymentTokenId && !f.expired);
    const farmsWithNoRenewals = farms.filter((f) => !f.paymentTokenId && !f.expired);
    const farmsWithThisOrg = farms.filter((f) => f.paymentTokenId && !f.expired);

    const ActionAlert = ({ text, children }) => {
        return (
            <div className={`u-mt-0 u-flexJustifyBetween Alert Alert--info u-flexStart`}>
                <div className="u-flex u-flexSelfStart">
                    <i className={"icon icon-alert"} />
                    <div style={{ marginTop: "3px" }}>{text}</div>
                </div>
                {children}
            </div>
        );
    };

    return (
        <>
            {creditCardModal && <CreditCardModal {...creditCardModal.modalProps} submitCard={setNewCard} />}
            <div>
                {cardRewewals.length > 0 && (
                    <Panel loading={!renewals} className="u-mb-xl" title="Credit card">
                        <ActionAlert text="Farms set up to automatically renew subscription by credit card.">
                            <div className="u-pl-md u-pr-md u-flex u-flexJustifyEnd">
                                <div>
                                    <ul className="u-flex">
                                        <li>
                                            <ActionLink disabled={!renewals || !selectedCreditCardFarmIds || selectedCreditCardFarmIds.length === 0} onClick={() => changeCard(selectedCreditCardFarmIds)} className="IconLink--copy" id="add-fertiliser">
                                                Change payment method
                                            </ActionLink>
                                        </li>
                                        <li className="u-pl-sm">
                                            <ActionLink onClick={() => removeFarmsConfirm(selectedCreditCardFarmIds)} disabled={!selectedCreditCardFarmIds || selectedCreditCardFarmIds.length === 0 || farmCount === 0} className="IconLink--trash" id="add-fertiliser">
                                                Remove auto renew
                                            </ActionLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </ActionAlert>
                        {cardRewewals.map((r, i) => {
                            const date = moment(r.paymentToken.expiresOn);
                            const expDate = date.isValid() ? date.format("MM/YY") : "-";
                            return (
                                <PanelBody key={i}>
                                    <div className="u-flex u-flexEnd u-mt-md">
                                        <Card card={r.paymentToken} />
                                        <div className="u-pb-md">
                                            <Button name={`${r.paymentToken.cardIdentifier}${expDate.replace("/", "")}`} id={`delete_card_${r.paymentToken.id}`} onClick={() => deleteCardConfirm(r)} secondary>
                                                Delete card
                                            </Button>
                                        </div>
                                    </div>
                                    <FarmList farms={r.farms} tokenId={r.paymentToken.id} selectedFarmIds={selectedCreditCardFarmIds} setSelectedFarmIds={setSelectedCreditCardFarmIds} message="Farms auto renewing using this card" pageSize={50} showFarmLink={true} />
                                </PanelBody>
                            );
                        })}
                    </Panel>
                )}

                {invoiceRewewals.length > 0 && (
                    <Panel loading={!renewals} className="u-mb-xl" title="Invoice">
                        <ActionAlert text="Farms set up to automatically renew subscription by invoice. There must be sufficient available balance at renewal.">
                            <div className="u-pl-md u-pr-md">
                                <div>
                                    <ul className="u-flex">
                                        <li>
                                            <ActionLink disabled={!renewals || !selectedInvoiceFarmIds || selectedInvoiceFarmIds.length === 0} onClick={() => changeCard(selectedInvoiceFarmIds)} className="IconLink--copy" id="add-fertiliser">
                                                Change payment method
                                            </ActionLink>
                                        </li>
                                        <li className="u-pl-sm">
                                            <ActionLink onClick={() => removeFarmsConfirm(selectedInvoiceFarmIds)} disabled={!selectedInvoiceFarmIds || selectedInvoiceFarmIds.length === 0 || farmCount === 0} className="IconLink--trash" id="add-fertiliser">
                                                Remove auto renew
                                            </ActionLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </ActionAlert>
                        <div className="u-ml-md">
                            <div>{authContext.isAdmin && balance && <BalanceSummary balance={balance} authContext={authContext} accountId={authContext.accountId} />}</div>
                        </div>

                        {invoiceRewewals.map((r, i) => {
                            return (
                                <PanelBody key={i}>
                                    <div className="u-flex u-flexEnd u-mt-md">
                                        <Card card={r.paymentToken} />
                                        <div className="u-pb-md">
                                            {false && (
                                                <Button name={`${r.paymentToken.cardIdentifier}`} id={`delete_card_${r.paymentToken.id}`} onClick={() => deleteCardConfirm(r)} secondary>
                                                    Delete payment method
                                                </Button>
                                            )}
                                        </div>
                                    </div>

                                    <FarmList farms={r.farms} tokenId={r.paymentToken.id} selectedFarmIds={selectedInvoiceFarmIds} setSelectedFarmIds={setSelectedInvoiceFarmIds} message="Farms auto renewing by invoice" pageSize={50} showFarmLink={true} />
                                </PanelBody>
                            );
                        })}
                    </Panel>
                )}

                {farms.length > 0 && (
                    <Panel loading={!farms} className="u-mb-xl" title="Farms not auto renewing with this organisation">
                        <ActionAlert text="Farms that your organisation has access to that are not set up to auto renew from your credit card or invoice.">
                            <div className="u-pl-md u-pr-md u-flex u-flexJustifyEnd">
                                <div>
                                    <ul className="u-flex">
                                        <li>
                                            <ActionLink disabled={!renewals || !selectedNoRenewalFarmIds || selectedNoRenewalFarmIds.length === 0} onClick={() => changeCard(selectedNoRenewalFarmIds)} className="IconLink--copy" id="add-fertiliser">
                                                Add payment method
                                            </ActionLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </ActionAlert>

                        <PanelBody>
                            {farmsWithNoRenewals.length > 0 && <FarmList farms={farmsWithNoRenewals} tokenId="None" selectedFarmIds={selectedNoRenewalFarmIds} setSelectedFarmIds={setSelectedNoRenewalFarmIds} message="Farms without auto renewal" pageSize={50} showPaidBy={false} />}
                            {farmsWithThisOrg.length > 0 && false && (
                                <div className="u-mt-md">
                                    <FarmList farms={farmsWithThisOrg} tokenId="None" message="Farms who renew with this organisation" pageSize={50} showPaidBy={true} selectedFarmIds={[]} setSelectedFarmIds={() => {}} />
                                </div>
                            )}
                            {farmsWithOtherOrgs.length > 0 && (
                                <div className="u-mt-md">
                                    <FarmList farms={farmsWithOtherOrgs} tokenId="None" selectedFarmIds={selectedNoRenewalFarmIds} setSelectedFarmIds={setSelectedNoRenewalFarmIds} message="Farms who renew with others" pageSize={50} showPaidBy={true} />
                                </div>
                            )}
                        </PanelBody>
                    </Panel>
                )}

                {expiredFarms.length > 0 && (
                    <Panel loading={!farms} className="u-mb-xl" title="Expired farms">
                        <ActionAlert type={"info"} text="Farms which have expired." />
                        <Panel className="u-mb-xl">
                            <PanelBody>
                                <BulkPayFarmList farms={expiredFarms} reload={fetchFarmsAndRenewals} pageSize={50} hideMyFarm={true} />
                            </PanelBody>
                        </Panel>
                    </Panel>
                )}
            </div>
        </>
    );
};

export default compose(withAppContext, withActions({ getMyOrgBalance, getRenewals, deleteCard, deleteAutoRenew, setCardForFarms, getNonRenewals }))(MyOrgRenewals);
